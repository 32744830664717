@import './styles/fonts';

html,
body {
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

* {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a9a9a9bd;
    border-radius: 6px;
    transition: background-color 0.4s;
    transition-timing-function: ease-in-out;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.href-link {
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
  color: #0062c9;
  &:hover {
    text-decoration: underline;
  }
}