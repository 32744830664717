@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:200,300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
.rfts-root-container{margin-top:5rem}.rfts-root-container.no-offset{margin-top:0}

.overlay{left:0;top:0;width:100%;height:100%;position:fixed;background:rgba(34,34,34,0.5);z-index:99999}.overlay .overlay__inner{left:0;top:0;width:100%;height:100%;position:absolute}.overlay .overlay__content{left:50%;position:absolute;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}.overlay .percentage{color:#fff;font-size:1rem}

.intro-video{display:flex;justify-content:center;padding-bottom:20px}

.signup-button-grid{margin:10px}

.subscription-card .card{cursor:pointer;height:100%}.subscription-card .card .card-header{font-family:'Nunito Sans', sans-serif}.subscription-card .card .card-header.bronze{background-color:#cd7f32ad}.subscription-card .card .card-header.silver{background-color:#c0c0c0}.subscription-card .card .card-header.platinum{background-color:#e5e4e2}.subscription-card .card .href-link{font-family:'Open Sans', sans-serif;cursor:pointer;font-size:1em;text-decoration:none;color:#0062c9}.subscription-card .card .href-link:hover{text-decoration:underline}.subscription-card .card .left-aligned-list{list-style-type:disc;margin-left:20px}

.subscription-cards-container{padding:20px}.subscription-cards-container h6{margin:0;margin-top:10px}.subscription-cards-container .signup-button-grid .signup-button{width:100%;padding:5px;background-color:transparent;box-shadow:none}

.subscription-info .page-title{font-family:'Work Sans', sans-serif;font-size:2.5em;font-weight:300}.subscription-info .page-sub-title{font-family:'Open Sans', sans-serif;font-size:1.5em;margin:10px;font-weight:400}.subscription-info .page-para{margin:10px;font-family:'Open Sans', sans-serif;font-size:1.2em;font-weight:400}.subscription-info .page-para b{font-family:'Open Sans', sans-serif;font-size:1em;font-weight:600}.subscription-info .href-link{font-family:'Open Sans', sans-serif;cursor:pointer;font-size:1em;text-decoration:none;color:#0062c9}.subscription-info .href-link:hover{text-decoration:underline}

label.stripe-label{color:#6b7c93;font-weight:300;letter-spacing:0.025em}button.stripe-button{white-space:nowrap;border:0;outline:0;display:inline-block;height:40px;line-height:40px;padding:0 14px;box-shadow:0 4px 6px rgba(50,50,93,0.11),0 1px 3px rgba(0,0,0,0.08);color:#fff;border-radius:4px;font-size:15px;font-weight:600;text-transform:uppercase;letter-spacing:0.025em;background-color:#6772e5;text-decoration:none;transition:all 150ms ease;margin-top:10px}button.stripe-button:hover{color:#fff;cursor:pointer;background-color:#7795f8;-webkit-transform:translateY(-1px);transform:translateY(-1px);box-shadow:0 7px 14px rgba(50,50,93,0.1),0 3px 6px rgba(0,0,0,0.08)}.StripeElement{display:block;width:100%;margin:10px 0 20px 0;max-width:500px;padding:10px 14px;font-size:1em;font-family:'Nunito Sans', sans-serif;box-shadow:rgba(50,50,93,0.14902) 0px 1px 3px,rgba(0,0,0,0.0196078) 0px 1px 0px;border:0;outline:0;border-radius:4px;background:white}.InputElement{font-size:18px;color:#424770;letter-spacing:0.025em;font-family:'Nunito Sans', sans-serif}.InputElement::-webkit-input-placeholder{color:#aab7c4}.InputElement::placeholder{color:#aab7c4}.InputElement:focus,.StripeElement--focus{box-shadow:rgba(50,50,93,0.109804) 0px 4px 6px,rgba(0,0,0,0.0784314) 0px 1px 3px;transition:all 150ms ease}.StripeElement.IdealBankElement,.StripeElement.FpxBankElement,.StripeElement.PaymentRequestButton{padding:0}.StripeElement.PaymentRequestButton{height:40px}.stripe-form{width:100%}

.interest-name .view-mp3-icon{padding:0;margin-left:5px}

.subscription-info-section .page-title{font-family:'Work Sans', sans-serif;font-size:2.5em;font-weight:300}.subscription-info-section .page-sub-title{font-family:'Open Sans', sans-serif;font-size:1.5em;margin:10px;font-weight:400}.subscription-info-section .page-para{margin:10px;font-family:'Open Sans', sans-serif;font-size:1.2em;font-weight:400}.subscription-info-section .page-para b{font-family:'Open Sans', sans-serif;font-size:1em;font-weight:600}.subscription-info-section .href-link{font-family:'Open Sans', sans-serif;cursor:pointer;font-size:1em;text-decoration:none;color:#0062c9}.subscription-info-section .href-link:hover{text-decoration:underline}

.subscription-footer .page-para{margin:10px;font-family:'Open Sans', sans-serif;font-size:1em;font-weight:400}.subscription-footer .page-para b{font-family:'Open Sans', sans-serif;font-size:1em;font-weight:600}

.mp3-list-container .page-title{font-family:'Work Sans', sans-serif;font-size:2.5em;margin-bottom:0;font-weight:300}.mp3-list-container .start-playing{margin-top:1rem;margin-bottom:1rem}.mp3-list-container .playlist-image{width:5rem}.mp3-list-container .mp3-list{margin-top:1rem}.mp3-list-container .mp3-list td,.mp3-list-container .mp3-list th{text-align:center}.mp3-list-container .mp3-list th{visibility:hidden}.mp3-list-container .mp3-list td>div{font-family:'Open Sans', sans-serif;font-size:1.2em;font-weight:400}.mp3-list-container .playlist-tabs{padding:10px;padding-top:0}.mp3-list-container .active{background-color:#303f9f;color:#fff;border-color:#303f9f}.mp3-list-container .active :hover{color:#303f9f}.mp3-list-container .mp3-list-title{display:flex;justify-content:flex-start;align-items:center;padding:5px;width:300px}.mp3-list-container .mp3-list-title .perSessionMp3-container{margin-left:10px;width:120px}.mp3-list-container .upgrade-section .upgrade-button-container{display:flex;justify-content:center}.page-popover{padding:10px;max-width:25rem}.page-popover .page-para{margin:10px;font-family:'Open Sans', sans-serif;font-size:1em;font-weight:400}.page-popover .page-para b{font-family:'Open Sans', sans-serif;font-size:1em;font-weight:600}

.playing-instructions .modal-para{display:block;color:black;font-size:1.1rem;margin:0.5rem}


.music-list-item{display:flex}.music-list-item .description-data{margin-left:15px;display:flex;justify-content:center;flex-direction:column}.music-list-item .description-data .description{font-weight:600}.music-list-item .description-data .description .description-text{margin-right:1rem}.music-list-item .description-data .description,.music-list-item .description-data .sku,.music-list-item .description-data .short-description{text-align:left}

.file-management-container .file-management-actions-container{margin-bottom:1.5rem}


.create-file-form .create-file-form-container{min-width:400px;max-width:500px}

.view-update-file-form .view-update-file-form-container{min-width:400px;max-width:500px}.view-update-file-form .view-update-file-form-container input{color:#1a2e1a}

.user-management-container .user-management-actions-container{margin-bottom:1.5rem}


.view-session-info>*{margin-bottom:0.7rem}

.view-interest-list-info>*{margin-bottom:0.7rem}

.interest-ordering-form .music-list{padding-left:40px;list-style-type:disc}


.create-user-form .create-user-form-container{min-width:400px;max-width:500px}.create-user-form #country{width:100%}.create-user-form .react-select .select__menu{z-index:2}.create-user-form .submit-button-container{padding:1rem 0}

.view-update-user-form .view-update-user-form-container{min-width:400px;max-width:500px}.view-update-user-form .view-update-user-form-container input{color:#1a2e1a}.view-update-user-form #country{width:100%}.view-update-user-form .react-select .select__menu{z-index:2}.view-update-user-form .submit-button-container{padding:1rem 0}


.interest-management-container .interest-management-actions-container{margin-bottom:1.5rem}

.create-interest-form{min-width:400px;max-width:500px}

.view-update-interest-form .view-update-interest-form-container{min-width:400px;max-width:500px}.view-update-interest-form .view-update-interest-form-container input{color:#1a2e1a}

.payment-details{padding:10px}

.first-responder-container .banner{width:100%}.first-responder-container .banner .banner-image{width:100%}.first-responder-container .page-title{font-family:'Work Sans', sans-serif;font-size:2.5em;font-weight:300}.first-responder-container .page-sub-title{font-family:'Open Sans', sans-serif;font-size:1.5em;margin:10px;font-weight:400}.first-responder-container .page-para{margin:10px;font-family:'Open Sans', sans-serif;font-size:1.2em;font-weight:400}.first-responder-container .page-para b{font-family:'Open Sans', sans-serif;font-size:1em;font-weight:600}.first-responder-container .signup-button-grid{margin:10px}

.first-responders-data-collector-form{padding:10px}



ul{margin:0;padding:0;list-style:none}

html,body{-ms-box-sizing:border-box;box-sizing:border-box;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}*{font-family:'Open Sans', sans-serif;font-size:14px}*{font-family:'Open Sans', sans-serif;font-size:14px}*::-webkit-scrollbar{width:8px}*::-webkit-scrollbar-thumb{background-color:#a9a9a9bd;border-radius:6px;-webkit-transition:background-color 0.4s;transition:background-color 0.4s;transition-timing-function:ease-in-out}*::-webkit-scrollbar-track{background-color:transparent}.href-link{font-family:'Open Sans', sans-serif;cursor:pointer;font-size:1em;text-decoration:none;color:#0062c9}.href-link:hover{text-decoration:underline}

